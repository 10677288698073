<template>
  <v-dialog v-model="dialog" max-width="800px" overlay-opacity="0.8">
    <v-card class="d-flex flex-column mx-auto" color="#16181B">
      <!--Header-->
      <div
        class="d-flex align-center px-6 py-8"
        style="
          background: radial-gradient(
            152.6% 32781.24% at 100% 50%,
            #1f2125 0%,
            rgba(31, 33, 37, 0) 100%
          );
        "
      >
        <v-icon v-text="'$hammer2'" />
        <span
          class="text--heading-1 mx-2"
          v-text="'Confirmação de Inscrição'"
        />
        <v-spacer />
        <v-btn icon @click="handleCancel">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </div>

      <!--Body-->
      <div
        v-if="item"
        class="d-flex flex-column align-center px-4 px-md-12 py-4"
      >
        <!--Submit info-->
        <span class="mt-2 text-center">
          <span class="text--body-1" v-text="'Você está prestes a se'" />
          <span v-html="'&nbsp;'" />
          <span
            class="text--body-1 font-weight-bold"
            v-text="`inscrever na ${item.name}.`"
          />
        </span>

        <!--Warn Info-->
        <span class="mt-8 text-center mb-4" style="max-width: 894px">
          <span
            class="text--body-2 font-weight-bold primary--text"
            v-text="'ATENÇÃO:'"
          />
          <span v-html="'&nbsp;'" />
          <span
            class="text--body-2"
            v-text="`Após realizar a sua inscrição na liga, você deve`"
          />
          <span v-html="'&nbsp;'" />
          <span
            class="text--body-2 font-weight-bold"
            v-text="'imprimir, preencher e assinar'"
          />
          <span v-html="'&nbsp;'" />
          <span
            class="text--body-2"
            v-text="'a documentação obrigatória. Em caso de'"
          />
          <span v-html="'&nbsp;'" />
          <span
            class="text--body-2 font-weight-bold"
            v-text="'não apresentação'"
          />
          <span v-html="'&nbsp;'" />
          <span
            class="text--body-2"
            v-text="'da documentação assinada, o jogador terá sua'"
          />
          <span v-html="'&nbsp;'" />
          <span
            class="text--body-2 font-weight-bold"
            v-text="'inscrição cancelada.'"
          />
          <span v-html="'&nbsp;'" />
        </span>

        <!--Document Buttons-->
        <div class="d-flex flex-column mt-2">
          <v-btn
            class="rounded-l-0 rounded-r-lg text-none mb-4"
            color="primary"
            target="_blank"
            href="https://drive.google.com/file/d/1GMuVwcygJWLRypS8xi5Lf46HZl3ltu7w/edit"
            outlined
          >
            IMPRIMIR AUTORIZAÇÃO<br v-if="$vuetify.breakpoint.smAndDown" />
            DE USO DE IMAGEM
          </v-btn>

          <v-btn
            class="rounded-l-0 rounded-r-lg text-none mb-2"
            color="primary"
            target="_blank"
            href="https://drive.google.com/file/d/1BUp3A7_-Y5GGJf5J1M2NPIre7qsCUlZy/edit"
            outlined
          >
            IMPRIMIR AUTORIZAÇÃO <br v-if="$vuetify.breakpoint.smAndDown" />
            PARA MENORES DE IDADE
          </v-btn>
        </div>

        <!--Terms-->
        <div class="d-flex flex-column align-center mt-8">
          <span
            class="text--body-2"
            v-text="'Para participar, confirme que leu as informações.'"
          />
          <div class="d-flex align-center">
            <v-checkbox v-model="terms" />
            <span>
              <span class="text--body-3" v-text="'Eu li e concordo com as'" />
              <span v-html="'&nbsp;'" />
              <a
                class="text--body-3 font-weight-bold primary--text"
                v-text="'Regras'"
              />
              <span v-html="'&nbsp;'" />
              <span class="text--body-3" v-text="'e'" />
              <span v-html="'&nbsp;'" />
              <a
                class="text--body-3 font-weight-bold primary--text"
                v-text="'Notas'"
              />
              <span v-html="'&nbsp;'" />
              <span v-text="'da Liga.'" />
            </span>
          </div>
        </div>

        <!--Actions-->
        <div class="d-flex justify-center">
          <SecondaryButton class="mx-2" text="Cancelar" @click="handleCancel" />
          <PrimaryButton
            class="mx-2"
            :disabled="!terms"
            text="Confirmar minha inscrição"
            @click="handleConfirm"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";
import SecondaryButton from "@/components/buttons/SecondaryButton";

export default {
  name: "JoinLeagueConfirmation",
  components: { SecondaryButton, PrimaryButton },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      terms: false,
    };
  },
  methods: {
    handleCancel() {
      this.dialog = false;
    },
    handleConfirm() {
      this.$emit("confirm");
      this.dialog = false;
    },

    handleClickDocument(index) {},

    formatX(value) {
      if (!value) return;

      value = value.toString();
      return `${value}x${value}`;
    },
  },
};
</script>

<style scoped></style>
