<template>
  <loader-view v-if="loading" />

  <div v-else>
    <page-header
      :background="selected.background || require('@/assets/leagues/img.png')"
      :title="selected.name"
    >
      <div v-if="selected.isActive" class="d-flex">
        <v-btn
          v-if="!subscribed"
          class="rounded-l-0 rounded-r-lg text-none"
          color="primary"
          :disabled="selected.status.value !== 0"
          @click="handleParticipate()"
        >
          <v-icon left>mdi-arrow-right</v-icon> Participar
        </v-btn>

        <v-btn
          v-else
          class="rounded-l-0 rounded-r-lg text-none"
          color="primary"
          @click="handleOpenTeam()"
        >
          <v-icon left>mdi-check</v-icon> Inscrito
        </v-btn>
      </div>
    </page-header>

    <v-container class="pt-12 pb-16">
      <v-tabs
        active-class="overtext--text"
        background-color="transparent"
        slider-color="primary"
        show-arrows
      >
        <v-tab
          v-for="(item, i) in tabs"
          :key="i"
          :to="`/league-details/${selected.id}/${item.path}`"
        >
          {{ item.label }}
        </v-tab>
      </v-tabs>

      <div class="pt-6">
        <router-view />
      </div>
    </v-container>

    <ProofDialog ref="proofDialog" @success="handleParticipate(true)" />

    <JoinLeagueConfirmation
      ref="confirm"
      :item="selected"
      @confirm="soloSubscribe()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getLeague } from "@/services/leagues";
import { createTeam } from "@/services/teams";
import { displayAlert } from "@/utils";
import JoinLeagueConfirmation from "@/components/leagues/JoinLeagueConfirmation";
import ProofDialog from "@/components/leagues/ProofDialog.vue";

export default {
  data() {
    return {
      loading: true,
      tabs: [
        {
          label: "Geral",
          path: "general",
        },
        {
          label: "Tabela",
          path: "table",
        },
        {
          label: "Equipes",
          path: "teams",
        },
        {
          label: "Participantes",
          path: "participants",
        },
        {
          label: "Regras",
          path: "rules",
        },
      ],
    };
  },

  components: {
    JoinLeagueConfirmation,
    ProofDialog,
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["localUser", "selected"]),

    subscribed() {
      if (!this.$store.getters.authenticated) return false;
      if (!this.selected) return false;

      return this.selected.teams.some((e) =>
        e.team_players.some((j) => j.user.id === this.localUser.id)
      );
    },
  },

  methods: {
    ...mapActions(["setSelected"]),

    async getData() {
      try {
        await getLeague(this.$route.params.id).then((res) => {
          this.setSelected(res);
          this.loading = false;
        });
      } catch (err) {
        this.displayAlert("Liga não encontrada", 1);
        this.$router.push({ path: "/leagues" });
      }
    },

    soloSubscribe() {
      const payload = {
        tag: "",
        name: this.localUser.nickname,
        logo:
          this.localUser.image ||
          "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team1.svg",
        leagueId: this.league.id,
      };

      createTeam(payload)
        .then((data) => {
          this.$router.push({ path: `/team-details/${data.id}` });
        })
        .catch(() =>
          this.displayAlert(
            "Falha ao tentar participar da liga, entre em contato com o administrador.",
            1
          )
        );
    },

    handleParticipate(force = false) {
      if (!this.$store.getters.authenticated) {
        const url = `${
          this.$store.getters.authURL
        }/auth?callback_url=${encodeURIComponent(
          this.$store.getters.appURL + "/home"
        )}`;

        return window.open(url, "_self");
      }

      if (!this.localUser.schoolCertificate && !force)
        this.$refs.proofDialog.handleDialog();
      else {
        if (this.selected.x < 2)
          return this.handleRequestConfirm(this.selected);
        this.$router.push({ path: `/teams/new?leagueId=${this.selected.id}` });
      }
    },

    handleRequestConfirm() {
      const element = this.$refs.confirm;
      if (!element) return;

      element.dialog = true;
    },

    handleOpenTeam() {
      const userId = this.localUser.id;

      const team = this.selected.teams.find((e) =>
        e.team_players.some((e) => e.user.id === userId)
      );
      if (!team) return;

      this.$router.push({ path: `/team-details/${team.id}` });
    },

    displayAlert,
  },
};
</script>

<style></style>
