<template>
  <v-btn
    class="primary-button__content"
    elevation="0"
    :disabled="disabled"
    :outlined="outlined"
    :text="flat"
    :color="color && color.length && color"
    @click="$emit('click', $event)"
  >
    <v-icon v-if="icon && icon.length" left small v-text="icon" />
    <span
      class="primary-button__text text--body-3 font-weight-bold"
      v-text="text"
    />
  </v-btn>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    color: {
      type: String,
      default: () => "primary",
    },
    icon: {
      type: String,
    },
    text: {
      type: String,
      default: () => "button",
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.primary-button__content {
  box-shadow: 0 1px 7px 0 #00000040 !important;
  border-radius: 0 7px 7px 0 !important;
}

.primary-button__text {
  text-transform: none;
}

.primary-button__content:hover {
  transform: scale(1.1);
}
</style>
